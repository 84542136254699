<template>
  <div class="forgot-password-page">
    <BackTitle class="mb-64" @click="goBack" />
    <Title class="mb-40">
      {{ isSent ? "Check your email" : "Forgot password?" }}
    </Title>
    <p v-if="!isSent" class="forgot-password-page__message mb-24">
      A link to reset your password will be sent to your email.
    </p>
    <Form v-slot="{ invalid }">
      <FormItem
        rules="required|email"
        v-slot="{ isError }"
        :class="isSent ? 'mb-24' : 'mb-120'"
      >
        <Input
          v-model="email"
          placeholder="Email"
          :is-error="isError"
          :is-disabled="isSent"
        />
      </FormItem>
      <p v-if="isSent" class="forgot-password-page__message mb-120">
        Follow the instructions in the email to set a new password to log in to
        your personal account.
      </p>
      <Button
        is-block
        :is-loading="isLoading"
        :is-disabled="!isSent && invalid"
        @click="handleClick"
      >
        {{ isSent ? "Go to login" : "Send" }}
      </Button>
    </Form>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { restorePassword } from "@/helpers/firebase-helpers";
export default {
  name: "ForgotPasswordPage",
  components: { BackTitle },
  data() {
    return {
      email: "",
      isLoading: false,
      isSent: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "Login",
      });
    },
    async handleClick() {
      if (!this.isSent) {
        try {
          this.isLoading = true;
          await restorePassword(this.email);
          this.isSent = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        await this.$router.push({
          name: "Login",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-page {
  &__message {
    font-size: 14px;
    line-height: 20px;
    color: $secondary-500;
  }
}
</style>
